import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  config = {
	  'api_url': 'https://myfst.adapts2ls.online/api/'
  };

  constructor(
	public http: HttpClient,
) { 

  }
  
  load () {

  }
  
  get (key) {
  	return this.config[key];
  }
}
