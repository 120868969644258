import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService }  from '../services/config.service';
import { Router } from '@angular/router';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  getSeal() {
    throw new Error('Method not implemented.');
  }
	public dataSource = new BehaviorSubject({});
	public data = this.dataSource.asObservable();
	public authState = new BehaviorSubject(true);

  constructor(
    public router: Router,
	public http: HttpClient,
	public configService: ConfigService
  ) {

  }

  generateSalt () {
	  return Math.random().toString(36).substr(2, 60) + Math.random().toString(36).substr(2, 60);
  }

  isAuthenticated() {
    return this.authState.value;
  }

	setAccessToken (token) {
		try {
			localStorage.setItem('accessToken', token);
			console.log('saved');
			console.log('saved' + token);
		} catch (e) {
			console.dir(e);
		}
	}

	setRefreshToken (token) {
		localStorage.setItem('refreshToken', token);
	}

	clearAccessToken () {
		localStorage.removeItem('accessToken');
	}

	clearRefreshToken () {
		localStorage.removeItem('refreshToken');
	}

	getAccessToken () {
		return localStorage.getItem('accessToken');
	}

	getRefreshToken () {
		return localStorage.getItem('refreshToken');
	}

	read (endpoint,  retry = true) {
		if (typeof(retry) === 'undefined') {
			retry = true;
		}

		var self = this;

		let apiUrl =  this.configService.get('api_url');
		let headers = new HttpHeaders().set('Authorization', 'Bearer: '+self.getAccessToken()+'');

		return new Promise(function(resolve, reject)
		{
			self.http.get(apiUrl + ''+endpoint+'', { withCredentials: true, headers: headers })
			.subscribe(data =>
			{
				let response: any = data;
				resolve(response);
			},
			error =>
			{
				if (error.status === 401) {
					self.logout();
					reject(error);
				}
				else if (error.status === 403) {
					self.logout();
					reject(error);
				} else {

				}
			});
		});
	}

	getCurrentUser () {
		let self = this;
		return this.read('users/info').then(function (data) {
			self.authState.next(true);
			self.dataSource.next(data);
		}).catch(function (data) {
			self.authState.next(false);
		});
	}

	logout () {
		let self = this;

		localStorage.removeItem('accessToken');
		localStorage.removeItem('refreshToken');

		self.authState.next(false);
		self.dataSource.next({});
	}

  authenticate (email, password) {
	var self = this;
	let apiUrl =  this.configService.get('api_url');
	return new Promise(function(resolve, reject)
	{
		let headers = new HttpHeaders().set('content-type', 'application/x-www-form-urlencoded');

		self.http.post(apiUrl + 'users/authenticate', "email="+email+"&password="+password+"" , { headers: headers,  withCredentials: true  })
		.subscribe(data =>
		{
			let response: any = data;

			self.setAccessToken(data['accessToken']);
			self.setRefreshToken(data['refreshToken']);

			self.getCurrentUser().then(function (data) {
				resolve(response);
			}).catch (function (data) {
				resolve(response);
			});
		},
		error =>
		{
			reject(error);
		});
	});
  }
}
