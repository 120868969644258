import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';

import { Router } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  isLoggedIn = false;
  currentUser:any;
  image = null;
  public pages = [];

  public footerPages = [
    { title: 'Uitloggen', url: 'logout', fontawesome: 'sign-out' },
  ];
  constructor(
		private router: Router,
		private platform: Platform,
		private authenticationService: AuthenticationService
    ) {		this.initializeApp();
	}

	initializeApp() {
		this.platform.ready().then(() => {

			var self = this;
      this.authenticationService.getCurrentUser().then(function (data) {
        self.isLoggedIn = true;
      }).catch (function (data) {

      });

      self.authenticationService.data.subscribe(data => {
        if (Object.keys(data).length) {
          self.currentUser = data;

          self.image = data['image'];

          self.pages = [
            { title: 'Dashboard', url: 'dashboard', fontawesome: 'th-large' },
            { title: 'Orders', url: 'transport-orders', fontawesome: 'truck' },
            { title: 'Organisations', url: 'organisations', fontawesome: 'building' },
            { title: 'Gebruikers', url: 'users', fontawesome: 'users' },
            { title: 'Mijn profiel', url: 'profile', fontawesome: 'user' }
          ];

          self.isLoggedIn = true;
        } else {
          self.currentUser = false;
          self.isLoggedIn = false;
        }
      });

			this.authenticationService.authState.subscribe(state => {
				if (state) {
          self.isLoggedIn = true;
				} else {
          self.isLoggedIn = false;

          let text = location.pathname;
          let result = text.includes("wachtwoord-instellen");

          if (!result) {
				  	this.router.navigate(['login']);
          }
				}
			});
		});
	}
  logout () {
  	this.authenticationService.logout();
  }
}
