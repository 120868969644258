import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/authguard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'transport-orders',
    pathMatch: 'full'
  },
  {
    path: 'transport-orders',
    loadChildren: () => import('./pages/transport-orders/transport-orders.module').then( m => m.TransportOrdersPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'transport-orders-details/:transportOrderId',
    loadChildren: () => import('./pages/transport-orders-details/transport-orders-details.module').then( m => m.TransportOrdersDetailsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'transport-orders-form/:transportOrderId',
    loadChildren: () => import('./pages/transport-orders-form/transport-orders-form.module').then( m => m.TransportOrdersFormPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'new-password',
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'new-password/:hash/:email',
    loadChildren: () => import('./pages/new-password/new-password.module').then( m => m.NewPasswordPageModule)
  },
  {
    path: 'wachtwoord-instellen/:hash/:email',
    loadChildren: () => import('./pages/new-password/new-password.module').then( m => m.NewPasswordPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'organisations',
    loadChildren: () => import('./pages/organisations/organisations.module').then( m => m.OrganisationsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'organisations-details/:organisationId',
    loadChildren: () => import('./pages/organisations-details/organisations-details.module').then( m => m.OrganisationsDetailsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'organisations-form/:organisationId',
    loadChildren: () => import('./pages/organisations-form/organisations-form.module').then( m => m.OrganisationsFormPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'transport-order-packages',
    loadChildren: () => import('./pages/transport-order-packages/transport-order-packages.module').then( m => m.TransportOrderPackagesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'transport-order-packages-details/:transportOrderPackageId',
    loadChildren: () => import('./pages/transport-order-packages-details/transport-order-packages-details.module').then( m => m.TransportOrderPackagesDetailsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'transport-order-packages-form/:transportOrderPackageId/:transportOrderId',
    loadChildren: () => import('./pages/transport-order-packages-form/transport-order-packages-form.module').then( m => m.TransportOrderPackagesFormPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'transport-order-packages-form/:transportOrderPackageId',
    loadChildren: () => import('./pages/transport-order-packages-form/transport-order-packages-form.module').then( m => m.TransportOrderPackagesFormPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'logout',
    loadChildren: () => import('./pages/logout/logout.module').then( m => m.LogoutPageModule)
  },
  {
    path: 'transport-orders-start',
    loadChildren: () => import('./pages/transport-orders-start/transport-orders-start.module').then( m => m.TransportOrdersStartPageModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./pages/users/users.module').then( m => m.UsersPageModule)
  },
  {
    path: 'users-form/:userId',
    loadChildren: () => import('./pages/users-form/users-form.module').then( m => m.UsersFormPageModule)
  },
  {
    path: 'transport-orders-thanks',
    loadChildren: () => import('./pages/transport-orders-thanks/transport-orders-thanks.module').then( m => m.TransportOrdersThanksPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
